<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Assigned Sample List</h2>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="12">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start">
            <v-col>
              <v-text-field
                label="Title"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.title
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start" align="center">
            <v-col md="2">
              <v-select
                label="Term"
                dense
                :items = terms
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
                v-model = searchForm.term
              ></v-select>
            </v-col>
          </v-row>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="proposalSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/proposals/assigned_samples?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <template>
          <div>
            <div class="d-flex justify-space-between align-end mb-5">
              <div class="text-caption">{{ total }} results</div>
              <v-btn color="deep-orange darken-1" dark @click="downloadAssignedSamples()"
                ><v-icon>mdi-download</v-icon>Download CSV</v-btn
              >
            </div>
            <v-card>
              <v-data-table
                :items="items"
                :headers="headers"
                :page.sync="page"
                hide-default-footer
                no-data-text="No data"
                disable-pagination
              >
                <template v-slot:item="{ item }">
                  <tr v-if="item.status !== 0"><!-- status = 0 （初期状態）の proposal は対象に含めない。 -->
                    <td>
                      <v-btn
                        x-small
                        outlined
                        :to="'/proposals/detail/' + item.id"
                        >DETAIL</v-btn
                      >
                    </td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.email }}<br>
                      {{ item.affiliation }}
                    </td>
                    <td>{{ item.title }}</td>
                    <td>
                      {{ item.global_score }}
                    </td>
                    <td class="py-3">
                      <span v-for="(sample,index) in item.assigned_samples" :key="sample.id">
                        <span v-if="index == 0">
                          {{sample_lists[sample.sample_id]}}
                        </span>
                        <span v-else>
                          , {{sample_lists[sample.sample_id]}}
                        </span>
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
            <div class="text-center pt-5">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="emitChangePage()"
                circle
                color="blue darken-4"
              ></v-pagination>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      errors: [],
      terms:[],
      status: [],
      reportStatus: [],
      global_score: [],
      sample_lists: [],
      rankRange: [],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        title: "",
        term: null,
        sample_id: null
      },
      breadcrumbs: [
        {
          text: "Proposal List",
          disabled: false,
          href: "/proposals",
        },
        {
          text: "Assigned Sample List",
          disabled: true,
          href: "/proposals/assigned_samples",
        },
      ],
      //search用
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "title",
        "term",
        "not_submitted",
        "submitted",
        "assigned",
        "reviewed",
        "scored",
        ], //検索条件
      searchURL: "/api/proposals/assigned_samples", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Id",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "12%",
        },
        {
          text: "Email / Affiliation",
          sortable: false,
          value: "email affiliation",
          width: "12%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Global Score",
          sortable: false,
          value: "global score",
          width: "5%",
        },
        {
          text: "Assigned Samples",
          sortable: false,
          value: "samples",
          width: "25%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get("/api/masters/report_status")
        .then((response) => {
          this.reportStatus = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/global_score_list")
        .then((response) => {
          this.global_score = response.data;
      });
      this.$axios
        .get(`/api/masters/getAllSamples`)
        .then(response => {
          for(let kw in response.data) {
            let name = response.data[kw].name;
            if ( response.data[kw].sample_type == 0) {
              name +=  ' [ ' + response.data[kw].weight + 'mg, ' + (response.data[kw].size/1000).toFixed(3) + 'mm ]';
            } else if ( response.data[kw].sample_type == 1) {
              name +=  ' [ ' + response.data[kw].weight + 'mg ]';
            } else if ( response.data[kw].sample_type == 2) {
              name +=  ' [ ' + response.data[kw].pressure + 'Pa, ' +response.data[kw].volume + 'ml ]';
            }
            this.sample_lists[response.data[kw].id] = name;
          }
        });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    proposalSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downloadAssignedSamples() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposals/download_assigned_samples', {
          params: {
            name: this.searchForm.name,
            affiliation: this.searchForm.affiliation,
            email: this.searchForm.email,
            title: this.searchForm.title,
            term_id: this.searchForm.term,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
  },
};
</script>
