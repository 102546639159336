<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposals/myproposal" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title
            class="px-4 py-2 d-flex justify-space-between"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Proposer
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Name</th>
                  <td class="text-h6">{{ fields.name }}</td>
                </tr>
                <tr>
                  <th>Affiliation</th>
                  <td class="text-subtitle-1">{{ fields.affiliation }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td class="text-subtitle-1">{{ fields.email }}</td>
                </tr>
                <tr>
                  <th>Early Career Scientist</th>
                  <td class="text-subtitle-1">
                    <v-radio-group row v-model="fields.early_career">
                      <v-radio
                        v-for="earlyCareer in earlyCareerScientists"
                        :key="earlyCareer.id"
                        :label="earlyCareer.label"
                        :value="earlyCareer.id"
                        color="blue darken-4"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card class="mt-5">
          <v-card-title
            class="px-4 py-2 indigo d-flex white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Previous Status
            </h3>
          </v-card-title>
          <div class="pa-5 pb-2 text-body-2">
            <p>
              If you have studied Ryugu samples previously via AO allocation(s), as part of initial analysis team or phase2 team (including your participation as a collaborator), please check the box(es) accordingly.
            </p>
          </div>
          <v-row class="px-10 pb-6 mb-4" justify="start">
            <!-- id=9999(Not applicated)の場合は他の選択肢のチェック不可、他の選択肢にチェックを入れた場合は9999のチェック不可-->
            <v-checkbox
              class="d-flex justify-start mr-5 mt-2"
              v-for="ost in other_status_list"
              dense
              hide-details
              :key="ost.id"
              :label="ost.label"
              :value="ost.id"
              :disabled="(check_not_applicated && ost.id != 9999) || (check_applicateted && ost.id == 9999)"
              v-model=accepted_terms_other
              >
            </v-checkbox>
            <v-checkbox
              class="d-flex justify-start mr-5 mt-2"
              v-for="pt in past_terms_list"
              dense
              hide-details
              :key="pt.id"
              :label="pt.label"
              :value="pt.id"
              :disabled="check_not_applicated"
              v-model=accepted_terms
              >
            </v-checkbox>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="11">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 indigo d-flex white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Collaborator
            </h3>
          </v-card-title>
          
          <v-card class="rounded-0">
            <div class="pa-5 pb-2 text-body-2">
              <p>
                Fill out the collaborator's information. Some sections are automatically filled out based on the provided information when your account was created. Please check the information, and correct it if necessary.
              </p>
            </div>
          </v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th style="width:15%;">First Name</th>
                  <th class="pl-0" style="width:10%;">Middle Name</th>
                  <th class="pl-0" style="width:15%;">Last Name</th>
                  <th class="pl-0" style="width:10%;">Suffix</th>
                  <th style="width:30%;">E-mail</th>
                  <th style="width:20%;">Affiliation</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in fields.proposal_collaborator"
                  :key="item.id"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="pt-2 pb-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.first_name
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.first_name'] ? errors['proposal_collaborator.' + index + '.first_name'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.first_name']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.first_name'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-2 pb-2 pl-0">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.middle_name
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.middle_name'] ? errors['proposal_collaborator.' + index + '.middle_name'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.middle_name']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.middle_name'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-2 pb-2 pl-0">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.last_name
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.last_name'] ? errors['proposal_collaborator.' + index + '.last_name'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.last_name']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.last_name'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-2 pb-2 pl-0">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.suffix
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.suffix'] ? errors['proposal_collaborator.' + index + '.suffix'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.suffix']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.suffix'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-2 pb-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.email
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.email'] ? errors['proposal_collaborator.' + index + '.email'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.email']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.email'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-2 pb-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=item.affiliation
                      :error-messages="
                        errors['proposal_collaborator.' + index + '.affiliation'] ? errors['proposal_collaborator.' + index + '.affiliation'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['proposal_collaborator.' + index + '.affiliation']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['proposal_collaborator.' + index + '.affiliation'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <v-btn small icon class="pt-1">
                      <v-icon @click.stop="deleteCollaborator(index)">
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
          <div class="text-right mt-4 pb-6">
            <v-btn @click="addCollaborator()">Add Collaborator
              <v-icon right medium class="ml-4">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
      </v-col>
    </v-row>





    <v-row justify="center">
      <v-col md="11">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 indigo d-flex white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Request Sample
            </h3>
          </v-card-title>

          <v-simple-table class="rounded-0">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="2">
                    <ul class="pa-4">
                      <li>Fill out the information about samples that you request in this section. Please note that the allocated samples and their quantities may differ from what you request when there is a conflict of interest regarding the samples among proposers.</li>
                      <li>Select the sample type from Particle, Aggregate, Gas, or Previously allocated sample. The maximum number of samples you can request is ten for a total of Particle and Aggregate, whereas there is no limitation for requesting Gas or Previously allocated samples. Gas is sampled in 45 mL of the gas cylinder, and the proposer cannot request the pressure level of the gas sample since the pressure level depends on the sampling status. </li>
                      <li>Remarks</li>
                        <ul>
                          <li>Describe the properties of samples you request to proceed with your research. Adding a detailed description or supplementary information for the samples you request in this section is preferable. </li>
                        </ul>
                      <li>Priority</li>
                        <ul>
                          <li>Please select the priorities of the sample you request. </li>
                          <li>Click the arrow icon next to the number of the priority to change the priority of the particular sample.</li>
                        </ul>
                      <li>Sample Type</li>
                        <ul>
                          <li>Particle: Select a sample name. Or click "Any," then enter the size (mm) or weight (mg) of the sample you request, and select a sample catcher chamber.</li>
                          <li>Aggregate: Select a sample name. Or click "Any," then select a sample catcher chamber.</li>
                          <li>Gas: Select a sample name. Enter the number of the 45 mL gas cylinder you request.</li>
                          <li>Previously allocated sample: Select a sample name.</li>
                        </ul>
                      <li>You can delete each row by clicking the trash icon on the right side of this section.</li>
                      <li>You can add a new row by clicking [ADD SAMPLES]. The total number of Particle and Aggregate is limited to ten, whereas the number of Gas or Previously allocated samples is unlimited.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th width="20%">Remarks
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Remarks</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Describe the properties of samples you request to proceed with your research. Please describe your proposal, especially analyses, in as detail as possible.<br>
                              Examples:<br>
                              <ul>
                                <li>Request samples that have absorption at xxx micro-m of wavelength by MicrOmega.</li>
                                <li>The sample volume should be over xx mg for xx analysis.</li>
                              </ul>
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model="fields.properties"
                      :error-messages="
                        word_count_properties > 200 ? 'Max 200 words' :'' || errors['properties'] ? errors['properties'] : ''
                      "
                      :error-count="
                        word_count_properties > 200 ? 1 : 0
                      "
                    ></v-textarea>
                    <div class="v-text-field__details" v-if="errors['properties']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['properties'][0] }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">{{ word_count_properties }} / 200</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Priority</th>
                  <th width="350pt">Type</th>
                  <th width="260pt">Sample Name</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th width="8%">&nbsp;</th>
                  <th>&nbsp;</th>
                  <th width="8%">&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in samples"
                  :key="item.id"
                >
                  <td>
                    {{ item.priority}}
                    <v-btn small icon class="pt-1 pl-2" v-if="index >= 1">
                      <v-icon @click.stop="changeSamplePriority(index)">
                        mdi-arrow-up
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-radio-group row v-model="item.sample_type">
                      <v-radio
                        v-for="st in sampleType"
                        :key="st.id"
                        :label="st.label"
                        :value="st.id"
                        color="blue darken-4"
                      ></v-radio>
                    </v-radio-group>
                  </td>

                  <td v-if="item.sample_type == 0" class="pt-0 pb-0 pl-0">
                    <v-autocomplete
                      dense
                      v-model=item.sample_id
                      item-text="label"
                      item-value="id"
                      :items=particle_sample_lists
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                      :disabled="item.any"
                      noDataText="Loading..."
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 0" class="pt-0 pb-0 pl-0">
                    <v-checkbox
                      v-model="item.any"
                      label="Any"
                      @change="changeAnyStatus(index)"
                    ></v-checkbox>
                  </td>
                  <!-- type == 0 && Any -->
                  <td v-if="item.sample_type == 0 && item.sampleUnit == 'mg' && item.any" width="100px" class="pt-0 pb-0 pl-0 pr-1">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      type="number"
                      step="0.1"
                      min="0"
                      v-model=item.weight
                      :error-messages="
                        (errors['samples.' + index + '.size'] || errors['samples.' + index + '.weight']) ? 'error' : ''
                      "
                    ></v-text-field>
                  </td>
                  <td v-if="item.sample_type == 0 && item.sampleUnit == 'mm' && item.any" width="100px" class="pt-0 pb-0 pl-0 pr-1">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      type="number"
                      step="0.01"
                      min="0"
                      v-model=item.size
                      :error-messages="
                        (errors['samples.' + index + '.size'] || errors['samples.' + index + '.weight']) ? 'error' : ''
                      "
                    ></v-text-field>
                  </td>
                  <td v-if="item.sample_type == 0 && item.any" class="pt-0 pb-0 pl-0 pr-1">
                    <v-autocomplete
                      dense
                      v-model=item.sampleUnit
                      item-text="label"
                      item-value="id"
                      :items=sampleUnitType
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                      @change="changeUnitStatus(index)"
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 0 && item.any" class="pt-0 pb-0 pl-0 pr-1" style="text-align: right;">
                    Chamber
                  </td>
                  <td v-if="item.sample_type == 0 && item.any" colspan="2" class="pt-0 pb-0 pl-0 pr-1">
                    <v-autocomplete
                      dense
                      v-model=item.chamber
                      item-text="label"
                      item-value="id"
                      :items=chamberList
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                    ></v-autocomplete>
                  </td>

                  <!-- type == 0 && SampleId -->
                  <td v-if="item.sample_type == 0 && !item.any" class="pt-0 pb-0 pl-0" colspan="4">
                    {{ sample_description_lists[item.sample_id] ? sample_description_lists[item.sample_id] : "" }}
                  </td>
                  <td v-if="item.sample_type == 0 && !item.any" class="pt-0 pb-0 pl-0">
                    <a
                      v-if="item.sample_id != '' && item.sample_id != null"
                      :href="detailUrl+item.sample_id"
                      target="_blank"
                    >&gt;&gt;detail</a>
                  </td>

                  <!-- type == 1 -->
                  <td v-if="item.sample_type == 1" class="pt-0 pb-0 pl-0">
                    <v-autocomplete
                      dense
                      v-model=item.sample_id
                      item-text="label"
                      item-value="id"
                      :items=aggregate_sample_lists
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                      :disabled="item.any"
                      noDataText="Loading..."
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 1" class="pt-0 pb-0 pl-0">
                    <v-checkbox
                      v-model="item.any"
                      label="Any"
                      @change="changeAnyStatus(index)"
                    ></v-checkbox>
                  </td>
                  <!-- type == 1 && Any -->
                  <td v-if="item.sample_type == 1 && item.any" class="pt-0 pb-0 pl-0">&nbsp;</td>
                  <td v-if="item.sample_type == 1 && item.any" class="pt-0 pb-0 pl-0">&nbsp;</td>
                  <td v-if="item.sample_type == 1 && item.any" class="pt-0 pb-0 pl-0 pr-1" style="text-align: right;">
                    Chamber
                  </td>
                  <td v-if="item.sample_type == 1 && item.any" colspan="2" class="pt-0 pb-0 pl-0 pr-1">
                    <v-autocomplete
                      dense
                      v-model=item.chamber
                      item-text="label"
                      item-value="id"
                      :items=chamberList
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                    ></v-autocomplete>
                  </td>

                  <!-- type == 1 && SampleId -->
                  <td v-if="item.sample_type == 1 && !item.any" class="pt-0 pb-0 pl-0" colspan="4">
                    {{ sample_description_lists[item.sample_id] ? sample_description_lists[item.sample_id] : "" }}
                  </td>
                  <td v-if="item.sample_type == 1 && !item.any" class="pt-0 pb-0 pl-0">
                    <a
                      v-if="item.sample_id != '' && item.sample_id != null"
                      :href="detailUrl+item.sample_id"
                      target="_blank"
                    >&gt;&gt;detail</a>
                  </td>

                  <!-- type == 2 -->
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0">
                    <v-autocomplete
                      dense
                      v-model=item.sample_id
                      item-text="label"
                      item-value="id"
                      :items=gas_sample_lists
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                      noDataText="Loading..."
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0">&nbsp;</td>
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0" colspan="2">
                    {{ sample_description_lists[item.sample_id] ? sample_description_lists[item.sample_id] : "" }}
                  </td>
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0" style="text-align: right;">Number of<br> bottles</td>
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0">
                    <v-autocomplete
                      dense
                      v-model=item.number_of_bottles
                      item-text="label"
                      item-value="id"
                      :items=[1,2,3]
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 2" class="pt-0 pb-0 pl-0">
                    <a
                      v-if="item.sample_id != '' && item.sample_id != null"
                      :href="detailUrl+item.sample_id"
                      target="_blank"
                    >&gt;&gt;detail</a>
                  </td>

                  <!-- type == 3 -->
                  <td v-if="item.sample_type == 3" class="pt-0 pb-0 pl-0">
                    <v-autocomplete
                      dense
                      v-model=item.sample_id
                      item-text="label"
                      item-value="id"
                      :items=processed_sample_lists
                      outlined
                      hide-details
                      color="brown"
                      background-color="white"
                      flat
                      noDataText="Loading..."
                    ></v-autocomplete>
                  </td>
                  <td v-if="item.sample_type == 3" class="pt-0 pb-0 pl-0">&nbsp;</td>
                  <td v-if="item.sample_type == 3" class="pt-0 pb-0 pl-0" colspan="4">
                    {{ sample_description_lists[item.sample_id] ? sample_description_lists[item.sample_id] : "" }}
                  </td>
                  <td v-if="item.sample_type == 3" class="pa-0">
                    <a
                      v-if="item.sample_id != '' && item.sample_id != null"
                      :href="detailUrl+item.sample_id"
                      target="_blank"
                    >&gt;&gt;detail</a>
                  </td>

                  <td class="pt-2 pb-2 pl-0 pr-1">
                    <v-btn small icon class="pt-1">
                      <v-icon @click.stop="deleteSample(index)">
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
          <div class="text-right mt-4 pb-6">
            <v-btn @click="addSample()">Add Sample
              <v-icon right medium class="ml-4">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
      </v-col>
    </v-row>

    <v-row class="mb-4" justify="center">
      <v-col md="11">
        <v-card>
          <v-card-title
            class="px-4 py-2 indigo d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Proposal
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th rowspan="2">
                    Attach proposal (PDF file)
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Attach proposal (PDF file)</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Attach your proposal file here. The file should be PDF formatted and less than 50 MB in size. Follow the template when you write your proposal. Confirm that your proposal includes support letters from research organizations to which PIs and collaborators belong.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td>
                    <v-row class="px-6">
                      <p class="pt-4">Uploaded file : <a :href="'/api/proposals/download_proposal/' + id">{{uploadFileName}}</a></p>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-file-input
                      type="file"
                      accept=".pdf"
                      label="Upload PDF"
                      v-model=fields.pdf
                      @change="updateFile"
                    ></v-file-input>
                  </td>
                </tr>
                <tr>
                  <th width="20%">Title
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Title</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Enter the title of your proposal.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="py-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model=fields.title
                      :error-messages="
                        errors['title'] ? errors['title'] : ''
                      "
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['title']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['title'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Abstract
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Abstract</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Enter the abstract of your proposal within 200 words.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.abstract
                      :error-messages="
                        word_count_abstract > 200 ? 'Max 200 words' : '' || errors['abstract'] ? errors['abstract'] : ''
                      "
                      :error-count="
                        word_count_abstract > 200 ? 1 : 0
                      "
                    ></v-textarea>
                    <div class="v-text-field__details" v-if="errors['abstract']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['abstract'][0] }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">{{ word_count_abstract }} / 200</div>
                  </td>
                </tr>
                <tr>
                  <th>Keywords
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Keywords</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Check all keywords which correspond to your research theme. Add keywords by clicking [ADD KEYWORD] as necessary. Please provide less than seven keywords.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-row class="px-6 mb-4" justify="start">
                      <v-checkbox
                        class="d-flex justify-start mr-5 mt-2"
                        v-for="keyword in keyword_lists"
                        dense
                        hide-details
                        :key="keyword.id"
                        :label="keyword.label"
                        :value="keyword.id"
                        v-model=keywords
                        >
                      </v-checkbox>
                    </v-row>
                    <v-row>
                      <div class="v-text-field__details pl-7" v-if="errors['keywords'] && !errors['keywords_other']">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="messages__message">{{ errors['keywords'][0] }}</div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                    <v-row class="px-6 pb-2" justify="start"
                      v-for="(item, index) in keywords_other"
                      :key="index"
                    >
                      <v-row class="pt-5" style="width: 100%;">
                        <v-text-field
                          solo
                          outlined
                          flat
                          color="black"
                          hide-details
                          dense
                          v-model="item.name"
                          :error-messages="
                            errors['keywords_other.' + index + '.name'] ? errors['keywords_other.' + index + '.name'] : ''
                          "
                        ></v-text-field>
                        <v-btn small icon class="pt-1">
                          <v-icon @click.stop="deleteKeyword(index)">
                            mdi-trash-can
                          </v-icon>
                        </v-btn>
                      </v-row>
                      <v-row>
                        <div class="v-text-field__details" v-if="errors['keywords_other.' + index + '.name']">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="messages__message">{{ errors['keywords_other.' + index + '.name'][0] }}</div>
                            </div>
                          </div>
                        </div>
                      </v-row>
                    </v-row>
                    <v-row>
                      <div class="v-text-field__details" v-if="errors['keywords_other']">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="pl-3 messages__message">{{ errors['keywords_other'][0] }}</div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                    <div class="text-right mt-4 pt-2">
                      <v-btn @click="addKeyword()">
                        Add Keyword
                        <v-icon right medium class="ml-4"
                        >mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Analysis Method
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Analysis Method</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Check all keywords which corresponds your research theme. Add methods by clicking [ADD ANALYSIS METHOD] as necessary. Within 20 keywords is acceptable.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-row class="px-6 mb-4" justify="start">
                      <v-checkbox
                        class="d-flex justify-start mr-5 mt-2"
                        v-for="method in analysis_method_lists"
                        dense
                        hide-details
                        :key="method.id"
                        :label="method.label"
                        :value="method.id"
                        v-model=methods
                        >
                      </v-checkbox>
                    </v-row>
                    <v-row>
                      <div class="v-text-field__details pl-7" v-if="errors['methods'] && !errors['methods_other']">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="messages__message">{{ errors['methods'][0] }}</div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                    <v-row class="px-6 pb-2" justify="start"
                      v-for="(item, index) in methods_other"
                      :key="index"
                    >
                      <v-row class="pt-5" style="width: 100%;">
                        <v-text-field
                          solo
                          outlined
                          flat
                          color="black"
                          hide-details
                          dense
                          v-model="item.name"
                          :error-messages="
                            errors['methods_other.' + index + '.name'] ? errors['methods_other.' + index + '.name'] : ''
                          "
                        ></v-text-field>
                        <v-btn small icon class="pt-1">
                          <v-icon @click.stop="deleteMethod(index)">
                            mdi-trash-can
                          </v-icon>
                        </v-btn>
                      </v-row>
                      <v-row>
                        <div class="v-text-field__details" v-if="errors['methods_other.' + index + '.name']">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="messages__message">{{ errors['methods_other.' + index + '.name'][0] }}</div>
                            </div>
                          </div>
                        </div>
                      </v-row>
                    </v-row>
                    <v-row>
                      <div class="v-text-field__details" v-if="errors['methods_other']">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="pl-3 messages__message">{{ errors['methods_other'][0] }}</div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                    <div class="text-right mt-4 pt-2">
                      <v-btn @click="addMethod()">
                        Add Analysis Method
                        <v-icon right medium class="ml-4"
                        >mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Effects on samples after the analysis
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Effects on samples after the analysis</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Describe how your work will affect the samples you have requested, the status, or the conditions of the remains of the samples.<br>
                              Examples:<br>
                              <ul>
                                <li>No samples will be returned to JAXA because of the destructive method of xxx analysis.</li>
                                <li>Some samples will be embedded in resin and divided into some pieces by xxx method.</li>
                              </ul>
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.post_analysis_alternation
                      :error-messages="
                        word_count_post_analysis_alternation > 200 ? 'Max 200 words' :'' || errors['post_analysis_alternation'] ? errors['post_analysis_alternation'] : ''
                      "
                      :error-count="
                        word_count_post_analysis_alternation > 200 ? 1 : 0
                      "
                    ></v-textarea>
                    <div class="v-text-field__details" v-if="errors['post_analysis_alternation']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['post_analysis_alternation'][0] }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">{{ word_count_post_analysis_alternation }} / 200</div>
                  </td>
                </tr>
                <tr>
                  <th>Handling Facility/Tools
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Handling Facility/Tools</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Describe the facilities where your research will be conducted or the tools you will use to handle samples.<br>
                              Examples:<br>
                              <ul>
                                <li>Micro-manipulator system by xxxx corp. is available.</li>
                                <li>The central part of this research is carried at xxx lab at the xxx institute.</li>
                              </ul>
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.methods_to_handle_of_sample
                      :error-messages="
                        word_count_methods_to_handle_of_sample > 200 ? 'Max 200 words' :'' || errors['methods_to_handle_of_sample'] ? errors['methods_to_handle_of_sample'] : ''
                      "
                      :error-count="
                        word_count_methods_to_handle_of_sample > 200 ? 1 : 0
                      "
                    ></v-textarea>
                    <div class="v-text-field__details" v-if="errors['methods_to_handle_of_sample']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['methods_to_handle_of_sample'][0] }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">{{ word_count_methods_to_handle_of_sample }} / 200</div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Transfer protocol of samples
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Transfer protocol of samples</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Mark the check box of "Use the JAXA standard container" if you like to use a standard package for the samples you request.
                              Mark the check box of "Use a commercial delivery service (i.e., FedEx)" if you like to use a commercial service for the sample distribution.
                              Please use the Remarks section to describe your request on the transfer protocol if the special preparation or shipping etc., is needed; a detailed description is preferable.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                      <v-checkbox
                        class="d-d-flex justify-start mt-2 text-body-1"
                        dense
                        hide-details
                        v-model="condition_2"
                        >
                        <template v-slot:label>
                          <div>
                            OK to use the JAXA standard container.
                            <!--
                              OK to 
                                <a
                                  target="_blank"
                                  href=""
                                >
                                  use the JAXA standard container.
                                </a>
                            -->                            
                          </div>
                        </template>
                      </v-checkbox>
                      <ul style="list-style-type: '- '">
                        <li>Particle: A stainless steel container (FFTC) holding pure N2 gas.</li>
                        <li>Aggregate: A stainless steel container (FFTC) holding with pure N2 gas.</li>
                        <li>Previously allocated sample: A vinyl/aluminum gas barrier bag holding N2 gas</li>
                        <li>Gas: A stainless steel cylinder (45 mL).</li>
                      </ul>

                      <v-checkbox
                        class="d-d-flex justify-start mt-3 mb-4 text-body-1"
                        dense
                        hide-details
                        v-model="condition_3"
                        >
                        <template v-slot:label>
                          <div>
                            OK to use a commercial delivery (i.e., FedEx).  
                          </div>
                        </template>
                      </v-checkbox>


                      <p class="mb-0 text-body-1"><span class="font-weight-bold">Remarks</span>: Please describe the details of the request in the box below if necessary.</p>
                      <v-row class="pt-5" style="width: 100%;">
                        <v-textarea
                          outlined
                          flat
                          color="black"
                          hide-details
                          dense
                          v-model=fields.environmental_condition_other
                          :error-messages="
                            errors['environmental_condition_other'] ? errors['environmental_condition_other'] : ''
                          "
                        ></v-textarea>
                      </v-row>
                      <v-row>
                        <div class="v-text-field__details" v-if="errors['environmental_condition_other']">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="messages__message">{{ errors['environmental_condition_other'][0] }}</div>
                            </div>
                          </div>
                        </div>
                      </v-row>
                    <div class="text-right pt-9">{{ word_count_environmental_condition_other }} / 200</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        to="/mypage"
        :disabled="buttonStatus"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<style>
.v-label {
  font-size: 1.0em;
}
</style>
<script>
export default {
  data() {
    return {
      errors: {},
      early_career: 0,
      loading: true,
      buttonStatus: false,
      register: false,
      fields: {},
      accepted_terms: [],
      accepted_terms_other: [],
      keywords: [],
      keywords_other: [],
      condition_2: false,
      condition_3: false,
      conditions: [],
      methods: [],
      methods_other: [],
      past_terms_list: [],
      other_status_list: [],
      keyword_lists: [],
      priority_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      priority_item: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      necessary_samples_list: ["", 1, 2, 3, 4, 5],
      file_info: null,
      samples: [
        {priority: 1, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 2, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 3, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 4, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 5, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 6, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 7, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 8, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 9, name: "1", sample_id: null, weight: 0, any: 0},
        {priority: 10, name: "1", sample_id: null, weight: 0, any: 0},
      ],
      analysis_method_lists: [],
      environmental_condition_lists: [],
      sample_description_lists: [],
      particle_sample_lists: [],
      aggregate_sample_lists: [],
      gas_sample_lists: [],
      processed_sample_lists: [],
      uploadFileSize: null,
      uploadFileType: null,
      uploadFileName: null,
      updateFileError: '',
      earlyCareerScientists: {},
      sampleType: {},
      breadcrumbs: [],
      id: this.$route.params.id,
      sampleUnitType: ["mg", "mm"],
      chamberList: [],
      showCollaboratorUsage: false,
      showSampleUsage: false,
      detailUrl: '',
    };
  },
  computed: {
    word_count_abstract: function () {
      return this.fields.abstract == '' || this.fields.abstract == null ? 0 : this.fields.abstract.match(/\S+/g).length;
    },
    word_count_properties: function () {
      return this.fields.properties == '' || this.fields.properties == null ? 0 : this.fields.properties.match(/\S+/g).length;
    },
    word_count_post_analysis_alternation: function () {
      return this.fields.post_analysis_alternation == '' || this.fields.post_analysis_alternation == null ? 0 : this.fields.post_analysis_alternation.match(/\S+/g).length;
    },
    word_count_methods_to_handle_of_sample: function () {
      return this.fields.methods_to_handle_of_sample == '' || this.fields.methods_to_handle_of_sample == null ? 0 : this.fields.methods_to_handle_of_sample.match(/\S+/g).length;
    },
    word_count_environmental_condition_other: function () {
      return this.fields.environmental_condition_other == '' || this.fields.environmental_condition_other == null ? 0 : this.fields.environmental_condition_other.match(/\S+/g).length;
    },
    check_not_applicated: function () {
      return this.accepted_terms_other.includes(9999);
    },
    check_applicateted: function () {
      return (!this.accepted_terms_other.includes(9999) && this.accepted_terms_other.length > 0) || this.accepted_terms.length > 0;
    }
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/Keyword`)
        .then(response => {
          for(let kw in response.data) {
            this.keyword_lists.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get(`/api/masters/proposal_previos_accepted_status`)
        .then(response => {
          for(let kw in response.data) {
            this.other_status_list.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get(`/api/masters/getPastTerms`)
        .then(response => {
          for(let kw in response.data) {
            this.past_terms_list.push({id: parseInt(kw), label: "AO" + kw});
          }
        });
      this.$axios
        .get(`/api/masters/AnalysisMethod`)
        .then(response => {
          for(let kw in response.data) {
            this.analysis_method_lists.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get(`/api/masters/EnvironmentalCondition`)
        .then(response => {
          for(let kw in response.data) {
            this.environmental_condition_lists.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get(`/api/masters/getSamples`)
        .then(response => {
          this.sample_description_lists = [];
          this.particle_sample_lists.push('', '');
          this.aggregate_sample_lists.push('', '');
          this.gas_sample_lists.push('', '');
          this.processed_sample_lists.push('', '');
          for(let kw in response.data) {
            if (response.data[kw].sample_type == 1) {
              const name = response.data[kw].name + ' [ ' + response.data[kw].weight + 'mg ]';
              this.aggregate_sample_lists.push({id: response.data[kw].id, label: name, type: response.data[kw].sample_type});
            } else if (response.data[kw].sample_type == 2) {
              const name = response.data[kw].name + ' [ ' + response.data[kw].pressure + 'Pa, ' + response.data[kw].volume + 'ml ]';
              this.gas_sample_lists.push({id: response.data[kw].id, label: name, type: response.data[kw].sample_type});
            } else if (response.data[kw].sample_type == 3) {
              const name = response.data[kw].name;
              this.processed_sample_lists.push({id: response.data[kw].id, label: name, type: response.data[kw].sample_type});
            } else {
              const name = response.data[kw].name + ' [ ' + response.data[kw].weight + 'mg, ' + (response.data[kw].size/1000).toFixed(3) + 'mm ]';
              this.particle_sample_lists.push({id: response.data[kw].id, label: name, type: response.data[kw].sample_type});
            }
            this.sample_description_lists[response.data[kw].id] = response.data[kw].description;
          }
        });
        this.$axios
          .get("/api/masters/early_career_type").then((response) => {
          this.earlyCareerScientists = response.data;
        });
        this.$axios
          .get("/api/masters/sample_type").then((response) => {
          this.sampleType = response.data;
        });
      this.$axios
        .get(`/api/masters/ChamberList`)
        .then(response => {
          this.chamberList = response.data;
        });
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then(response => {
          this.detailUrl = response.data;
        });
      this.$axios
        .get(`/api/proposals/edit/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;

          //Accepted Termsの値を生成
          this.accepted_terms = [];
          this.accepted_terms_other = [];
          for(let key in response.data.accepted_terms) {
            let at = response.data.accepted_terms[key];
            if (at.term_id) {
              this.accepted_terms.push(at.term_id);
            } else {
              this.accepted_terms_other.push(at.other_status_id);
            }
          }

          //keywordsの値を生成
          this.keywords = [];
          this.keywords_other = [];
          for(let key in response.data.selected_keywords) {
            let kw = response.data.selected_keywords[key]   
            if (kw.keyword_id == null) {
              this.keywords_other.push({name: kw.keyword_other});
            } else {
              this.keywords.push(kw.keyword_id);
            }
          }
          
          //methodsの値を生成
          this.methods = [];
          this.methods_other = [];
          for(let key in response.data.selected_methods) {
            let method = response.data.selected_methods[key]   
            if (method.analysis_method_id == null) {
              this.methods_other.push({name: method.analysis_method_other});
            } else {
              this.methods.push(method.analysis_method_id);
            }
          }
          
          //conditionのチェックボックス制御用
          for(let key in response.data.selected_conditions) {
            let condition = response.data.selected_conditions[key]
            if (condition.environmental_condition_id == 2) {
              this.condition_2 = true;
            }
            if (condition.environmental_condition_id == 3) {
              this.condition_3 = true;
            }
          }

          //samplesの値を生成
          this.samples = [];
          let sampleCnt = 0;
          for(let key in response.data.requested_samples) {
            let rs = response.data.requested_samples[key];
            rs.any = false;
            if (rs.sample_id == null) {
              rs.any = true;
            }
            rs.sampleUnit = rs.size == null ? 'mg' : 'mm';
            if (rs.size != null) {
              // DBにはµmで保存されているので1000で割る
              rs.size = (rs.size / 1000).toFixed(2); 
            }
            this.samples.push(rs);
            sampleCnt++;
          }
          if (sampleCnt < 10) {
            for(var i = sampleCnt; i < 10; i++) {
              const defaultSample = {priority: i+1, sample_type: 0, any: false, sample_id: null, weight: 0, size: 0, chamber: 0, number_of_bottles: 1, sampleUnit: 'mg'};
              this.samples.push(defaultSample);
            }
          }
          this.loading = false;

          //ファイル名の取得
          this.uploadFileName = this.fields.file.original_file_name;

        })
        .catch((error) => {
          if (error.response.status == "403") {
            this.$router.replace({
                path: "/403",
            });
          }
          this.loading = false;
        });
    })();
  },
  methods: {
    addCollaborator() {
      this.fields.proposal_collaborator.push({ name: "" });
    },
    deleteCollaborator(index) {
      this.fields.proposal_collaborator.splice(index, 1);
    },
    addSample() {
      const defaultSample = {priority: this.samples.length + 1, sample_type: 0, any: false, sample_id: null, weight: 0, size: 0, chamber: 0, number_of_bottles: 0, sampleUnit :'mg'};
      this.samples.push(defaultSample);
    },
    addKeyword() {
      this.keywords_other.push({ name: "" });
    },
    deleteKeyword(index) {
      this.keywords_other.splice(index, 1);
    },
    addMethod() {
      this.methods_other.push({ name: '' });
    },
    deleteMethod(index) {
      this.methods_other.splice(index, 1);
    },
    changeSamplePriority(index) {
      this.samples[index].priority = index;
      this.samples[index - 1].priority = index + 1;
      this.samples.sort((a, b) => {
          return a.priority - b.priority;
        });
    },
    deleteSample(index) {
      this.samples.splice(index, 1);
      for(var i = index; i < this.samples.length; i++) {
        this.samples[i].priority -= 1;
      }
    },
    changeAnyStatus(index) {
      this.$set(this.samples, index, this.samples[index]);
    },
    changeUnitStatus(index) {
      this.$set(this.samples, index, this.samples[index]);
    },
    updateFile(event) {
      if(event){
        this.uploadFileSize = event.size;
        this.uploadFileType = event.type;
        this.uploadFileName = event.name;
        this.validateUploads();
      } else {
        this.uploadFileSize = null;
        this.uploadFileType = null;
        this.uploadFileName = null;
      }
    },
    validateUploads() {
      this.updateFileError = '';
      //上限サイズは50MB
      if (this.uploadFileSize && this.uploadFileSize > 50000000) {
        this.updateFileError += 'Max file size is 50MB.\n'
      }
      
      //拡張子はpdfのみ許可
      if (this.uploadFileType && this.uploadFileType != 'application/pdf') {
        this.updateFileError += 'Only PDF files can be uploaded.\n'
      }

      //エラーが存在する場合はアラートを表示
      if (this.updateFileError) {
        alert(this.updateFileError);
        return false;
      }
      return true;
    },
    submit() {
      this.loading = true;
      this.buttonStatus = true;
      let submitFlg = this.validateUploads();
      if(!submitFlg) {
        this.loading = false;
        this.buttonStatus = false;
        return false;
      }
      const data = new FormData();
      data.append("check_organization", this.fields.check_organization ? this.fields.check_organization : 0);
      data.append("check_collaborators", this.fields.check_collaborators ? this.fields.check_collaborators : 0);
      data.append("early_career", this.fields.early_career ? this.fields.early_career : 0);
      data.append("title", this.fields.title ? this.fields.title : '');
      data.append("abstract", this.fields.abstract ? this.fields.abstract : '');
      data.append("properties", this.fields.properties ? this.fields.properties : '');
      data.append("post_analysis_alternation", this.fields.post_analysis_alternation ? this.fields.post_analysis_alternation : '');
      data.append("methods_to_handle_of_sample", this.fields.methods_to_handle_of_sample ? this.fields.methods_to_handle_of_sample : '');
      data.append("file", this.fields.pdf ? this.fields.pdf : '');
      data.append("environmental_condition_other", this.fields.environmental_condition_other ? this.fields.environmental_condition_other : '');

      //proposal_collaboratorのデータ作成
      Object.keys(this.fields.proposal_collaborator).forEach(key => {
        Object.keys(this.fields.proposal_collaborator[key]).forEach(value => {
          data.append('proposal_collaborator[' + key + '][' + value + ']', this.fields.proposal_collaborator[key][value] ? this.fields.proposal_collaborator[key][value] : '');
        });
      });

      //samplesのデータ作成
      Object.keys(this.samples).forEach(key => {
        // 選択されていないデータは除外する
        let sample_type = this.samples[key]['sample_type'];
        switch(sample_type) {
          case 1:
            // Aggregate
            if (this.samples[key]['sample_id'] || this.samples[key]['any']) {
              data.set('samples[' + key + '][type]', 1);
              data.set('samples[' + key + '][sample_id]', this.samples[key]['sample_id'] ?? '');
              data.set('samples[' + key + '][any]', this.samples[key]['any'] ? 1 : 0 );
              data.set('samples[' + key + '][chamber]', this.samples[key]['chamber'] ? this.samples[key]['chamber'] : '');
            }
            break;
          case 2:
            // Gas：SampleID, Number of bottles
            if (this.samples[key]['sample_id']) {
              data.set('samples[' + key + '][type]', 2);
              data.set('samples[' + key + '][any]', 0);
              data.set('samples[' + key + '][sample_id]', this.samples[key]['sample_id']);
              data.set('samples[' + key + '][number_of_bottles]', this.samples[key]['number_of_bottles'] ? this.samples[key]['number_of_bottles'] : 1);
            }
            break;
          case 3:
            // Processed：SampleID
            if (this.samples[key]['sample_id']) {
              data.set('samples[' + key + '][type]', 3);
              data.set('samples[' + key + '][any]', 0);
              data.set('samples[' + key + '][sample_id]', this.samples[key]['sample_id']);
            }
            break;
          default:
            // Particle
            if (this.samples[key]['sample_id'] || this.samples[key]['any']) {
              data.set('samples[' + key + '][type]', 0);
              data.set('samples[' + key + '][sample_id]', this.samples[key]['sample_id'] ?? '');
              data.set('samples[' + key + '][any]', this.samples[key]['any'] ? 1 : 0 );
              if (this.samples[key]['any'] == 1) {
                if (this.samples[key]['sampleUnit'] == 'mm') {
                  data.set('samples[' + key + '][size]', this.samples[key]['size']);
                  data.set('samples[' + key + '][weight]', '');
                } else {
                  data.set('samples[' + key + '][weight]', this.samples[key]['weight']);
                  data.set('samples[' + key + '][size]', '');
                }
                data.set('samples[' + key + '][chamber]', this.samples[key]['chamber'] ? this.samples[key]['chamber'] : '');
              }
            }
            break;
        }
      });

      //AcceptedTermsのデータ作成
      Object.keys(this.accepted_terms).forEach(key => {
        data.append('accepted_terms[' + key + ']', this.accepted_terms[key] ? this.accepted_terms[key] : '');
      });

      //accepted_status_otherのデータ作成
      Object.keys(this.accepted_terms_other).forEach(key => {
        data.append('accepted_terms_other[' + key + ']', this.accepted_terms_other[key] ? this.accepted_terms_other[key] : '');
      });

      //keywordsのデータ作成
      Object.keys(this.keywords).forEach(key => {
        data.append('keywords[' + key + ']', this.keywords[key] ? this.keywords[key] : '');
      });

      //keywords_otherのデータ作成
      Object.keys(this.keywords_other).forEach(key => {
        Object.keys(this.keywords_other[key]).forEach(value => {
          data.append('keywords_other[' + key + '][' + value + ']', this.keywords_other[key][value] ? this.keywords_other[key][value] : '');
        });
      });

      //methodsのデータ作成
      Object.keys(this.methods).forEach(key => {
        data.append('methods[' + key + ']', this.methods[key] ? this.methods[key] : '');
      });

      //methods_otherのデータ作成
      Object.keys(this.methods_other).forEach(key => {
        Object.keys(this.methods_other[key]).forEach(value => {
          data.append('methods_other[' + key + '][' + value + ']', this.methods_other[key][value] ? this.methods_other[key][value] : '');
        });
      });

      //conditionsのデータ作成
      if (this.condition_2 === true) {
        data.append('conditions[]', 2);
      }
      if (this.condition_3 === true) {
        data.append('conditions[]', 3);
      }

      const headers = { "content-type": "multipart/form-data", 'X-HTTP-Method-Override': 'PUT' };
      this.errors = {};
      this.$axios.post(`/api/proposals/edit/${this.$route.params.id}`, data, { headers }).then(response => {
        this.loading = false;
        this.buttonStatus = false;
        if (response.data.result == true) {
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "success",
          });
          this.$router.back()
        }
      })
      .catch((error) => {
        this.loading = false;
        this.buttonStatus = false;
        if (error.response.status === 400) {
          this.$store.commit("setMessage", {
            text: "Error: Save failed. If there are red-colored areas on the input form, it may cause this error. Please input information with the proper rule.",
            color: "error",
          });
          this.errors = error.response.data.errors;
        } else if (error.response.status == 403) {
          this.$router.replace({
              path: "/403",
          });
        }
      });
    },
  },
 };
</script>
